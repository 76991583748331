<!-- 用户授权页面 -->
<template>
  <div>
    <div class="tool">
      <div>
        <a-select v-model:value="field" @change="onFieldChange">
          <a-select-option value="username">用户名</a-select-option>
          <a-select-option value="realname">姓名</a-select-option>
          <a-select-option value="nickname">昵称</a-select-option>
        </a-select>
        <a-input-search
          v-model:value="keyword"
          placeholder="请输入关键词"
          enter-button
          @search="onSearch"
          @change.stop="onSearchChange"
        />
      </div>
      <div>
        <a-button type="primary" @click="doBacthAuthorize">
          <UnlockOutlined />批量授权
        </a-button>
      </div>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="userList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          fixed: true,
        }"
      >
        <template #action="{ record }">
          <span>
            <a-button type="link" size="small" @click="doAuthorize(record)">
              <UnlockOutlined />授权
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
  <a-modal
    :title="isMultiple ? '用户批量授权' : '用户授权'"
    okText="确定"
    cancelText="取消"
    v-model:visible="showAuthorizeModal"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-radio-group
      v-model:value="defaultValue"
      :options="roleOptions"
      @change.stop="onRadioGroupChange"
    />
  </a-modal>
</template>


<script>
import { reactive, toRefs, onMounted, createVNode } from "vue";
import {
  UnlockOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { getUserListPage } from "@/api/user";
import { getRoleList } from "@/api/permis/role";
import { getRoleByUserId, updateUserRole } from "@/api/user";
import { message, Modal } from "ant-design-vue";
export default {
  name: "Authorize",
  components: {
    UnlockOutlined,
  },
  setup() {
    const state = reactive({
      field: "username",
      keyword: "",
      loading: false,
      selectedRowKeys: [],
      selectUserinfo: {
        uid: null,
        uname: "",
        rid_old: null,
        rname_old: "",
        rid_new: null,
        rname_new: "",
      },
      searchParam: {
        mode: "B", //授权模式
        searchField: "",
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
      },
      userList: [],
      defaultValue: null,
      roleOptions: [],
      showAuthorizeModal: false,
      isMultiple: false,
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "姓名",
        dataIndex: "realname",
        key: "realname",
        //width: 200,
      },
      {
        title: "工号",
        dataIndex: "code",
        key: "code",
        //width: 200,
      },
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
        //width: 200,
      },
      {
        title: "昵称",
        dataIndex: "nickname",
        key: "nickname",
        //width: 200,
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        //width: 200,
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      initUserList(state.searchParam);
    });
    const initUserList = (searchParam) => {
      state.loading = true;
      getUserListPage(searchParam).then((resp) => {
        if (resp) {
          state.userList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      state.searchParam = {
        mode: "B",
        searchField: state.field,
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 10,
      };
      initUserList(state.searchParam);
    };
    const onSearchChange = (e) => {
      state.keyword = e.data;
      state.searchParam.searchValue = state.keyword;
      state.searchParam.searchField = state.field;
    };
    const onFieldChange = (value) => {
      state.field = value;
      state.searchParam.searchField = state.field;
    };
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initUserList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.searchValue;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initUserList(state.searchParam);
    };
    // const rowSelection = {
    //   selectedRowKeys: state.selectedRowKeys,
    //   onChange: (selectedRowKeys) => {
    //     state.selectedRowKeys = selectedRowKeys;
    //   },
    // };
    const doAuthorize = (record) => {
      state.showAuthorizeModal = true;
      state.isMultiple = false;
      initRoleList();
      initUserRole(record.id);
      state.selectUserinfo.uid = record.id;
      state.selectUserinfo.uname = record.username;
    };
    const doBacthAuthorize = () => {
      console.log(state.selectedRowKeys);
      if (state.selectedRowKeys.length > 0) {
        state.showAuthorizeModal = true;
        state.isMultiple = true;
        initRoleList();
      } else {
        message.info("请您选择需要授权的用户！！！");
      }
    };
    const initRoleList = () => {
      getRoleList().then((resp) => {
        if (resp) {
          state.roleOptions = [];
          resp.map((element) => {
            state.roleOptions.push({
              label: element.name,
              value: element.id,
            });
          });
        }
      });
    };
    const initUserRole = (uid) => {
      getRoleByUserId(uid).then((resp) => {
        if (resp.length > 0) {
          state.defaultValue = resp[0].id;
          state.selectUserinfo.rid_old = resp[0].id;
          state.selectUserinfo.rname_old = resp[0].name;
        } else {
          state.defaultValue = null;
        }
      });
    };
    const handleCancel = () => {
      state.showAuthorizeModal = false;
      state.isMultiple = false;
      state.defaultValue = null;
    };
    const handleOk = () => {
      if (state.isMultiple) {
        if (state.defaultValue) {
          Modal.confirm({
            title: "温馨提示",
            icon: createVNode(ExclamationCircleOutlined),
            okText: "确认",
            cancelText: "取消",
            content: createVNode(
              "div",
              { style: "color:red;" },
              "此操作将所选用户批量授权为【" +
                state.selectUserinfo.rname_new +
                "】角色，是否继续？"
            ),
            onOk() {
              updateUserRole({
                isMultiple: state.isMultiple,
                uids: state.selectedRowKeys,
                rid: state.defaultValue,
              }).then((resp) => {
                if (resp) {
                  state.showAuthorizeModal = false;
                  state.defaultValue = null;
                  state.selectedRowKeys = [];
                  retsetUserInfo();
                }
              });
            },

            onCancel() {
              message.info("已取消用户批量授权操作！！！");
              state.showAuthorizeModal = false;
              state.defaultValue = null;
              retsetUserInfo();
            },
          });
        } else {
          Modal.confirm({
            title: "温馨提示",
            icon: createVNode(ExclamationCircleOutlined),
            okText: "确认",
            cancelText: "取消",
            content: createVNode(
              "div",
              { style: "color:red;" },
              "您尚未选择任何角色，是否放弃此次操作？"
            ),
            onOk() {
              message.info("已取消用户批量授权操作！！！");
              state.showAuthorizeModal = false;
              state.defaultValue = null;
              retsetUserInfo();
            },
            onCancel() {
              message.info("请您继续选择一个角色！！！");
            },
          });
        }
      } else {
        if (state.defaultValue) {
          if (
            state.selectUserinfo.rid_new == null ||
            state.selectUserinfo.rid_new == state.selectUserinfo.rid_old
          ) {
            message.info("请您选择一个新的角色！！！");
          } else {
            Modal.confirm({
              title: "温馨提示",
              icon: createVNode(ExclamationCircleOutlined),
              okText: "确认",
              cancelText: "取消",
              content: createVNode(
                "div",
                { style: "color:red;" },
                "此操作将更新用户【" +
                  state.selectUserinfo.uname +
                  "】的角色为【" +
                  state.selectUserinfo.rname_new +
                  "】，是否继续？"
              ),
              onOk() {
                updateUserRole({
                  isMultiple: state.isMultiple,
                  uid: state.selectUserinfo.uid,
                  rid: state.selectUserinfo.rid_new,
                }).then((resp) => {
                  if (resp) {
                    state.showAuthorizeModal = false;
                    state.defaultValue = null;
                    retsetUserInfo();
                  }
                });
              },

              onCancel() {
                message.info("已取消用户授权操作！！！");
                state.showAuthorizeModal = false;
                state.defaultValue = null;
                retsetUserInfo();
              },
            });
          }
        } else {
          Modal.confirm({
            title: "温馨提示",
            icon: createVNode(ExclamationCircleOutlined),
            okText: "确认",
            cancelText: "取消",
            content: createVNode(
              "div",
              { style: "color:red;" },
              "您尚未给用户【" +
                state.selectUserinfo.uname +
                "】选择任何角色，是否放弃此次操作？"
            ),
            onOk() {
              message.info("已取消用户授权操作！！！");
              state.showAuthorizeModal = false;
              state.defaultValue = null;
              retsetUserInfo();
            },
            onCancel() {
              message.info("请您继续为该用户选择一个角色！！！");
            },
          });
        }
      }
    };
    const onRadioGroupChange = (e) => {
      state.selectUserinfo.rid_new = e.target.value;
      let role = state.roleOptions.find((r) => r.value === e.target.value);
      state.selectUserinfo.rname_new = role.label;
    };
    const retsetUserInfo = () => {
      state.selectUserinfo = {
        uid: null,
        uname: "",
        rid_old: null,
        rname_old: "",
        rid_new: null,
        rname_new: "",
      };
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initUserList,
      onSearch,
      onSearchChange,
      onFieldChange,
      onSelectChange,
      onChangePage,
      onShowSizeChange,
      doAuthorize,
      doBacthAuthorize,
      initRoleList,
      initUserRole,
      handleCancel,
      handleOk,
      retsetUserInfo,
      onRadioGroupChange,
    };
  },
};
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.ant-btn {
  margin-right: 5px;
}
.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>